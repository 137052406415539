/*!
 * Equilex main JS
 **/
var equilex = (function($, undefined) {
	'use strict';

	var menu = function() {
		var $opener = $('.menuMobile'),
			$menu = $('.menu'),
			open = false;

		$opener.on('click', function() {
			$menu.toggleClass('menu--showMobile');
			open = !open;
		});

		$menu.on('click', '.menu-item-has-children .menuLink', function(event) {
			if (open) {
				event.preventDefault();
				$('.menuList-sub').slideUp();
				$(this).next('.menuList-sub').slideToggle();
			}
		});
	};

	var slick = function($element) {
		$element.slick({
			infinite: false,
			slidesToShow: 3,
			responsive: [{
				breakpoint: 1279,
				settings: {
					slidesToShow: 2
				}
			},
			{
				breakpoint: 1020,
				settings: {
					slidesToShow: 1,
					arrows: false
				}
			}]
		});
	};

	var collapser = function(element) {
		var $this = $(element),
			$target = $($this.attr('href'));

		$this.toggleClass('collapseLink--open');
		$target.slideToggle();
		$target.toggleClass('collapseContent--open');

		event.preventDefault();
	};

	var slideToogleCollapseProducts = function(target, element) {

			target.slideToggle(400, function() {
				if (element.hasClass('collapseLink--open'))
					element.closest('.collapse').find('ul.taglist li a').removeClass('hidden');
				else
					element.closest('.collapse').find('ul.taglist li a:not(.active)').addClass('hidden');
			});

	};

	var collapserProducts = function(elements, active_element) {
		var $active_target = active_element.attr('href');
		active_element.toggleClass('collapseLink--open');
		active_element.toggleClass('collapseContent--open');
		$('[data-collapse-products]').each(function(i, item) {
			var $this = $(this);
			var $target = $($this.attr('href'));
			if ($active_target != $this.attr('href')) {
				if ($this.hasClass('collapseLink--open')) {
					$this.toggleClass('collapseLink--open');
					slideToogleCollapseProducts($target, $this);
					$target.toggleClass('collapseContent--open');
				}
			}
		});


		slideToogleCollapseProducts($($active_target), active_element);
	};

	var retina = function() {
		var dpr = window.devicePixelRatio;
		if (dpr === 2) {
			$('img[data-retina]').each(function() {
				$(this).attr('src', function(i, attr) {
					return attr.replace('450x709', '900x1418');
				});
			});
		}
	};

	var init = function() {
		var md = new MobileDetect(window.navigator.userAgent);

		// Images retina
		retina();

		// Menu mobile
		menu();

		// Slider news home
		slick($('.slider'));

		// Scroll custom
		if (!md.mobile() || md.tablet()) {
			$('.scroll-pane').jScrollPane({
				verticalGutter: 0,
				contentWidth: '0px',
				autoReinitialise: true
			});
		}
		var scrollpane = $('.scroll-pane').data('jsp');

		// Tabs
		$('.pagenav').tabs({
			class: 'pagenavItem--active',
			anchors: true,
			onComplete: function() {
				if (typeof(scrollpane) != 'undefined') {
					scrollpane.reinitialise();
				}
			}
		});

		var throttleTimeout;
		$(window).bind('resize', function() {
			if (!throttleTimeout) {
				throttleTimeout = setTimeout( function() {
					if (typeof(scrollpane) != 'undefined') {
						scrollpane.reinitialise();
					}
					throttleTimeout = null;
				}, 50 );
			}
		});

		// Collapse
		var contentHeight = $('#our-team .text-content').height();
		$('[data-collapse]').on('click', function(event) {
			collapser($(this));

			if (contentHeight !== $('#our-team .text-content').height()) {
				scrollpane.reinitialise();
			}
		});

		$('[data-collapse-products]').on('click', function(event) {
			collapserProducts('[data-collapse-products]', $(this));

			if (contentHeight !== $('#our-team .text-content').height()) {
				scrollpane.reinitialise();
			}
			event.preventDefault();
		});

		$('.pageproducts-link').click(function(event) {
			$('.page-content').show();
			if (md.mobile() || md.tablet()) {
				$('.pageproducts-nav').hide();
			}
		});

		$('.pageproducts-nav').tabs({
			class: 'pageproducts--active',
			anchors: true,
			onComplete: function() {
				if (typeof(scrollpane) != 'undefined') {
					scrollpane.reinitialise();
				}
				if (md.mobile()) {
					// $('.pageproducts-nav').slideUp();
					// $('.pageproducts-filters-content').slideUp();
				}
			}
		});

		// expand nav automatically
		var productvisible = 0;
		if (window.location.hash) {
			if (!productvisible) {
				$('a.pageproducts-link[href="' + window.location.hash + '"]').trigger('click');
				$('a.pageproducts-link[href="' + window.location.hash + '"]').parent().addClass('pageproducts--active');
				productvisible = window.location.hash;
			}

			if ($('.about-us ' + window.location.hash).length || $('.services ' + window.location.hash).length || $('.eh-s ' + window.location.hash).length) {
				$(window.location.hash).show().siblings().hide();
			}
		}

		if (md.mobile() || md.tablet()) {
			$('.pageproducts-filters-btn').on('click', function() {
				$('.pageproducts-nav').hide();
				$('.pageproducts-filters-content').fadeIn();
				$('.pageproducts-selection-btn').removeClass('active');
				$('.page-content').hide();
			});
			$('.pageproducts-selection-btn').on('click', function() {
				$('.pageproducts-filters-content').hide();
				$('.pageproducts-nav').fadeIn();
			});
		}

		$('.newsLink').on('click', function(event) {
			event.stopPropagation();
			event.preventDefault();

			var $this = $(this),
				$target = $($this.attr('href')),
				$parent = $this.parent(),
				$items = $parent.siblings();

			$items.addClass('newsItem--fade');
			$items.removeClass('newsItem--active');
			$parent.addClass('newsItem--active');
			$parent.removeClass('newsItem--fade');

			$target.siblings().hide();
			$target.fadeIn(function() {
				if (typeof(scrollpane) != 'undefined') {
					scrollpane.reinitialise();
				}
			});

			if (typeof(scrollpane) != 'undefined') {
				scrollpane.reinitialise();
			}

			$('.newsContent').fadeIn();

			if (window.location.hash) {
				$('newsLink[href="' + window.location.hash + '"]').trigger('click');
			}

			if (md.mobile()) {
				$('.newsList').slideUp();
				$('.news-showall').fadeIn();
			}
		});

		if ($('.newsLink').length && window.location.hash !== '') {
			$('.newsLink[href="' + window.location.hash + '"]').trigger('click');
		}

		$('.pageproducts-filters-content').find('[data-state="expanded"]').each(function(index, el) {
			collapser($(this));
		});

		// Tags
		$('.tag-close').on('click', function() {
			var $parent = $(this).parent();

			$parent.fadeOut();

			setTimeout(function() {
				$parent.remove();
			}, 600);
		});

		// Affichagedes
		$('.btn-contact-form').on('click', function(event) {
			var data = $(this).attr('data-product');
			sessionStorage.setItem('equilex-product', data);
		});

		if ($('.wpcf7-form').length && 'equilex-product' in sessionStorage) {
			$('.wpcf7-select.form-control').val(sessionStorage.getItem('equilex-product'));
		}

		if (md.mobile()) {
			$('.productItem').on('click', function() {
				window.location.href = $(this).attr('data-mobile-link');
			});

			$('.news-showall').on('click', function() {
				$(this).fadeOut();
				$('.newsList').slideDown();
				$('.newsContainer .newsContent').slideUp().find('.newsresult').slideUp();
				$('.newsItem').removeClass('newsItem--active newsItem--fade');
			});
		}
	};

	return {
		init: init
	};

})(jQuery);

$(function() {
	equilex.init();
});